/* eslint-disable @eslint-react/web-api/no-leaked-event-listener */
import { isSmartTVUserAgent } from "@/utils/helpers/Utils";
import onIdle, { OnIdleOptions } from "@/utils/onIdle";
import { ReactNode, useEffect, useState } from "react";

export default function RenderOnIdle(props: {
  children: ReactNode;
  onRender?: () => void;
  /** if specified, the render will only happen when one of the refs is hovered, but still only when idle */
  renderOnHoverRef?: React.RefObject<HTMLElement | null>;
  onIdleOptions?: OnIdleOptions;
}) {
  const isSmartTV = isSmartTVUserAgent(navigator.userAgent);
  const [render, setRender] = useState(isSmartTV); // Default to `true` for Smart TVs

  useEffect(() => {
    if (isSmartTV) return; // Skip effect logic for Smart TVs

    const controller = new AbortController();
    const abortSignalsArr = [controller.signal];

    if (props.onIdleOptions?.signal) {
      abortSignalsArr.push(props.onIdleOptions.signal);
    }
    const abortSignals = AbortSignal.any(abortSignalsArr);

    if (props.renderOnHoverRef) {
      props.renderOnHoverRef.current?.addEventListener(
        "mouseover",
        () => {
          onIdle(
            () => {
              setRender(true);
            },
            { ...props.onIdleOptions, signal: abortSignals }
          );
        },
        { once: true, signal: abortSignals }
      );
    } else {
      onIdle(
        () => {
          setRender(true);
        },
        { ...props.onIdleOptions, signal: abortSignals }
      );
    }

    return () => controller.abort("RenderOnIdle unmounted");
  }, [isSmartTV]);

  useEffect(() => {
    if (render) {
      props.onRender?.();
    }
  }, [render]);

  if (!render) {
    return null;
  }

  return props.children;
}
