declare global {
  interface Window {
    scheduler: {
      postTask: <T>(
        callback: () => T,
        options?: {
          priority?: "user-blocking" | "user-visible" | "background";
          signal?: AbortSignal;
          delay?: number;
        }
      ) => Promise<T>;
    };
  }
}

export type OnIdleOptions = {
  signal?: AbortSignal;
} & Parameters<typeof window.scheduler.postTask>[1];

// oxlint-disable-next-line prefer-await-to-callbacks
export default function onIdle<T>(callback: () => T, options?: OnIdleOptions) {
  if ("scheduler" in window && "postTask" in window.scheduler) {
    // eslint-disable-next-line promise/prefer-await-to-then, @typescript-eslint/no-unsafe-call
    window.scheduler.postTask<T>(callback, options).catch(() => {
      // ignore
    });
  }
  if ("requestIdleCallback" in window) {
    const idleCallbackId = requestIdleCallback(callback);
    options?.signal?.addEventListener(
      "abort",
      () => {
        cancelIdleCallback(idleCallbackId);
      },
      { once: true }
    );
  }
  const timeoutId = setTimeout(callback, 0);
  options?.signal?.addEventListener(
    "abort",
    () => {
      clearTimeout(timeoutId);
    },
    { once: true }
  );
}
